import { useEffect, useState } from "react";
import { AuthContext } from ".";
import PropTypes from "prop-types";
import { activeUser } from "utils/api";
import Loader from "components/common/Loader";

const AuthContextProvider = ({ children }) => {
  const [authLoading, setAuthLoading] = useState(true);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [user, setUser] = useState(null);

  const getActiveUser = async () => {
    setAuthLoading(true);
    try {
      const { data } = await activeUser();
      setIsLoggedin(true);
      setUser(data?.data);
    } catch (error) {
      console.log("🚀 ~ getActiveUser ~ error:", error);
    }
    setAuthLoading(false);
  };

  useEffect(() => {
    getActiveUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isLoggedin,
        setIsLoggedin,
        authLoading,
      }}
    >
      {authLoading ? (
        <div className="flex h-screen w-full flex-col items-center justify-center">
          <Loader size="large" />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};
