import axios from "axios";

// Server URL
const serverUrl = process.env.REACT_APP_SERVER_URL;

// Creating a New Axios Instance
const axiosInstance = axios.create({
  baseURL: serverUrl,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

/**
 * A class with different static methods for different operations
 * @method post
 * @method get
 * @method put
 * @summary All the api call are hit here
 */
export default class Api {
  // POST method
  static async post(config) {
    const { data, url, headers = {}, ...restConfig } = config;
    const postConfig = {
      headers: {
        ...axiosInstance.defaults.headers,
        ...headers,
      },
      ...restConfig,
    };
    return axiosInstance.post(url, data, postConfig);
  }

  // GET Method
  static async get(config) {
    const { url } = config;
    return axiosInstance.get(url);
  }

  // PUT method
  static async patch(config) {
    const { data, url, headers = {}, ...restConfig } = config;
    const patchConfig = {
      headers: {
        ...axiosInstance.defaults.headers,
        ...headers,
      },
      ...restConfig,
    };
    return axiosInstance.patch(url, data, patchConfig);
  }
}
