import * as yup from "yup";

/**
 * Login Form Input Schemas
 * @field Email
 * @field Password
 */
export const loginSchema = yup
  .object()
  .shape({
    atcCode: yup.string().required("Email is required."),
    password: yup.string().required("Password is required."),
  })
  .required();
