import { path } from "constants/path";
import MainLayout from "layouts/mainLayout";
import { AllResponse, Login } from "pages/index";
import { Route, Routes } from "react-router-dom";

const RouterComp = () => {
  return (
    <Routes>
      <Route path={path.home} element={<MainLayout />}>
        <Route path={path.home} element={<AllResponse />} />
      </Route>
      <Route path={path.login} element={<Login />} />
    </Routes>
  );
};

export default RouterComp;
