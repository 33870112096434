import UserProfile from "components/userProfile/userProfile";
import { useAuth } from "hooks";
import PropTypes from "prop-types";

const Header = () => {
  const { user, logout } = useAuth();
  return (
    <>
      <div className="w-full shadow-md p-3 flex justify-between items-center sticky top-0 bg-white z-20">
        <p className="font-bold text-xl">CDAC - Gujarat</p>
        <div className="relative w-max group">
          <UserProfile name={user.atcName} />
          <div className="absolute drop-shadow-md bg-white right-0 h-0 overflow-hidden group-hover:h-[149px] transition-all duration-300 group-hover:border">
            <ul className="divide-y-2 [&>*]:py-2 [&>*]:px-2 w-52 text-center">
              <li className="hover:bg-gray-100">
                <span className="italic text-sm">Location Id:</span>{" "}
                {user.atcLocation}
              </li>
              <li className="hover:bg-gray-100">
                <span className="italic text-sm">ATC Code:</span> {user.atcCode}
              </li>
              <button
                onClick={logout}
                className="hover:bg-red-100 text-red-800 w-full"
              >
                Logout
              </button>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

Header.propTypes = {
  menuAction: PropTypes.func,
};
