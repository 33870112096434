import AllResponsesTable from "components/allResponses/AllResponsesTable";

const AllResponses = () => {
  return (
    <div className="h-[70vh]">
      <AllResponsesTable />
    </div>
  );
};

export default AllResponses;
