import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "validators/login.schema";
import { useAuth } from "hooks/index";
import { path } from "constants/path";

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });
  const { login } = useAuth();

  return (
    <section className="bg-white max-w-lg text-gray-600 drop-shadow-lg flex justify-center items-center p-4 rounded-md">
      <div className="container mx-auto px-5">
        {/* <LogoHeader /> */}
        <div className="mb-6 flex w-full flex-col text-center">
          <h3 className="title-font mb-4 text-xl font-normal text-[#111827] sm:text-3xl">
            ATC Admin Portal
          </h3>
        </div>
        <div className="mx-auto">
          <div className="-m-2 flex flex-wrap">
            <form onSubmit={handleSubmit(login)} className="w-full">
              <div className="w-full p-2">
                <div className="relative">
                  <label
                    htmlFor="atcCode"
                    className="text-sm leading-7 text-gray-600"
                  >
                    ATC Code
                  </label>
                  <input
                    type="text"
                    id="atcCode"
                    name="atcCode"
                    placeholder="Enter your ATC Code here"
                    {...register("atcCode")}
                    className={`w-full rounded border border-gray-300 bg-gray-100 bg-opacity-50 px-3 py-1 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-[#2aa9e7] focus:bg-white focus:ring-2 focus:ring-indigo-200 ${
                      errors.atcCode ? "border-red-800" : ""
                    }`}
                  />
                  <span className="text-sm text-red-800">
                    {errors.atcCode?.message}
                  </span>
                </div>
              </div>
              <div className="w-full p-2">
                <div className="relative">
                  <label
                    htmlFor="password"
                    className="text-sm leading-7 text-gray-600"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Enter your password here"
                    {...register("password")}
                    className={`w-full rounded border border-gray-300 bg-gray-100 bg-opacity-50 px-3 py-1 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-[#2aa9e7] focus:bg-white focus:ring-2 focus:ring-indigo-200 ${
                      errors.password ? "border-red-800" : ""
                    }`}
                  />
                  <span className="text-sm text-red-800">
                    {errors.password?.message}
                  </span>
                </div>
              </div>
              <div className="flex w-full items-end justify-between gap-y-3 p-2 max-sm:flex-col  max-sm:items-start">
                <button
                  type="submit"
                  className="mt-4 flex rounded border-0 bg-[#111827] px-8 py-2 text-lg text-white hover:bg-[#2f3b54] focus:outline-none"
                >
                  Log in
                </button>
                <span className="text-right text-[#111827]">
                  New to this app?
                  <Link
                    to={path.signup}
                    className="underline underline-offset-4"
                  >
                    Signup here
                  </Link>
                </span>
              </div>
            </form>
            {/* <OauthPart /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
