import { API_PATH } from "utils/constants";
import Api from "./apiInstance";

// To a website response
export const getWebsiteResponse = ({ page = 1, limit = 10 }) => {
  return Api.get({
    url: `${API_PATH.RESPONSE}?page=${page}&limit=${limit}`,
  });
};

// To update response follow up status
export const updateResponseFollowUp = (id, data) => {
  return Api.patch({
    url: `${API_PATH.RESPONSE}/${id}`,
    data,
  });
};
