import { LoginForm } from "components/login";
import { path } from "constants/path";
import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const { isLoggedin } = useAuth();
  console.log("🚀 ~ Login ~ isLoggedin:", isLoggedin);

  useEffect(() => {
    if (isLoggedin) {
      navigate(path.home);
    }
  }, [isLoggedin]);

  return (
    <div className="bg-gray-50 w-screen h-screen flex flex-col items-center justify-center">
      <h3 className="text-center text-4xl font-semibold mb-6 underline underline-offset-4">CDAC - Gujarat</h3>
      <LoginForm />
    </div>
  );
};

export default Login;
