const UserProfile = ({ name }) => {
  const initials = name.split(" ")[0][0];

  return (
    <div className="p-2 border-2 rounded-md flex items-center relative">
      <div className="rounded-full w-8 aspect-square bg-blue-900 flex items-center justify-center text-xs font-semibold text-white mr-2">
        {initials}
      </div>
      <span className="font-semibold">{name}</span>
    </div>
  );
};

export default UserProfile;
