import { API_PATH } from "utils/constants";
import Api from "./apiInstance";

// To get active user
export const activeUser = () => {
  return Api.get({ url: API_PATH.ACTIVE_USER });
};

// To Login user
export const userLogin = (data) => {
  return Api.post({ url: API_PATH.LOGIN, data: data });
};

// To Logout user
export const userLogout = () => {
  return Api.post({ url: API_PATH.LOGOUT });
};
