import { Header } from "components/header";
import { path } from "constants/path";
import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const MainLayout = () => {
  const navigate = useNavigate();

  const { isLoggedin } = useAuth();


  useEffect(() => {
    if (!isLoggedin) {
      navigate(path.login);
    }
  }, []);

  return (
    isLoggedin && (
      <>
        <Header />
        <div className={`transition-all duration-300 flex-1 p-4`}>
          <Outlet />
        </div>
      </>
    )
  );
};

export default MainLayout;
