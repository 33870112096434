export const API_PATH = {
  // Auth
  ACTIVE_USER: "/auth",
  LOGIN: "/auth/login",
  LOGOUT: "/auth/logout",

  // Websites
  WEBSITE: "/website",
  VERIFY_WEBSITE: "/website/verify/:id",

  // Response
  RESPONSE: "/response",
};
