import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const Table = ({ rows, cols, ...props }) => {
  return (
    <div className="ag-theme-quartz h-full w-full" style={{ width: "100%" }}>
      <AgGridReact
        rowData={rows}
        columnDefs={cols}
        defaultColDef={{ filter: true }}
        style={{ width: "100%" }}
        {...props}
      />
    </div>
  );
};

export default Table;
