import { useSearchParams } from "react-router-dom";

const useQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * set the param in url
   *
   * @param {string} key
   * @param {any} value
   */
  const setQuery = (key, value) => {
    if (typeof value === "object") {
      searchParams.set([key], JSON.stringify(value));
    } else {
      searchParams.set([key], value);
    }
    setSearchParams(searchParams);
  };

  const getQuery = (key) => {
    const value = searchParams.get(key);
    if (!value) return null;
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  };

  const removeQuery = (key) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const getAllQueries = () => {
    const queries = {};
    for (const key of searchParams.keys()) {
      queries[key] = getQuery(key);
    }
    return queries;
  };

  return { setQuery, getQuery, removeQuery, getAllQueries };
};

export default useQuery;
