export const path = {
  // Pages
  home: "/",
  allResponse: "/all-responses",
  response: "/response",
  // General
  login: "/login",
  signup: "/signup",
  addWebsite: "/add-website",
};
