import { FOLLOW_UP_STATUS } from "constants";
import useResponses from "hooks/useResponses";
import React from "react";

const UpdateFollowUpBtn = ({ id, value }) => {
  const { updateFollowUp } = useResponses();

  return (
    <select
      id="followUp"
      name="followUp"
      autoComplete="followUp-name"
      required
      defaultValue={value}
      onChange={(e) => updateFollowUp(id, e.target.value)}
      className={`w-full cursor-pointer flex justify-between bg-white rounded-md border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
    >
      {FOLLOW_UP_STATUS.map((status) => (
        <option value={status} key={status}>
          {status}
        </option>
      ))}
    </select>
  );
};

export default UpdateFollowUpBtn;
