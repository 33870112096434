import { useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context";
import { userLogin, userLogout } from "utils/api";
import { path } from "constants/path";

const useAuth = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const login = async (formData) => {
    const loading = toast.loading("Logging in...");
    try {
      const { data } = await userLogin(formData);
      console.log("🚀 ~ login ~ data:", data);

      // Update the context
      authContext?.setUser(data.data);
      authContext?.setIsLoggedin(true);

      toast.update(loading, {
        render: `Welcome ${data?.data?.atcName}`,
        type: "success",
        isLoading: false,
        autoClose: 4000,
      });

      navigate(path.home);
    } catch (error) {
      toast.update(loading, {
        render: error?.response?.data?.message || "Some error occured!",
        type: "error",
        isLoading: false,
        autoClose: 4000,
      });
    }
  };

  const signup = async (data) => {
    console.log("🚀 ~ login ~ data:", data);
  };

  const logout = async () => {
    const loading = toast.loading("Loggin out...");
    try {
      await userLogout();

      // Update the context
      authContext?.setIsLoggedin(false);

      navigate(path.login, { replace: true });

      toast.update(loading, {
        render: "User Logged out successfully",
        type: "success",
        isLoading: false,
        autoClose: 4000,
      });
    } catch (error) {
      console.log("🚀 ~ logout ~ error:", error);
    }
  };

  return { login, signup, logout, ...authContext };
};

export default useAuth;
