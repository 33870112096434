import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AuthContextProvider from "context/auth.context";
import { toastConfig } from "utils/constants";
import RouterComp from "./routes";

import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <AuthContextProvider>
          <ToastContainer {...toastConfig} />
          <RouterComp />
        </AuthContextProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
