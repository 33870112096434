import { useEffect } from "react";
import { Table, TableController } from "components/common/table";
import useResponses from "hooks/useResponses";
import UpdateFollowUpBtn from "./updateFollowUpBtn";
import moment from "moment";

const tableCols = [
  { field: "name", headerName: "Name" },
  { field: "mobile", headerName: "Mobile" },
  { field: "email", headerName: "Email" },
  {
    field: "interestedCourse",
    headerName: "Interested Course",
    flex: 1,
    cellRenderer: ({ data }) => {
      return data.interestedCourse.map((course) => course.name).join(", ");
    },
  },
  { field: "message", headerName: "Message" },
  {
    field: "followUp",
    headerName: "Follow Up",
    cellRenderer: ({ data }) => {
      return <UpdateFollowUpBtn id={data._id} value={data.followUp} />;
    },
  },
  {
    field: "createdAt",
    headerName: "Inquiry Received On",
    cellRenderer: ({ data }) => {
      return data.createdAt
        ? moment(data.createdAt).format("DD/MM/YYYY - HH:mm")
        : "";
    },
  },
];

const AllResponsesTable = () => {
  const { responses, getResponse } = useResponses();

  useEffect(() => {
    getResponse();
  }, []);

  return (
    <>
      <div className="w-full flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-center">Dashboard</h2>
        <button
          onClick={getResponse}
          className="border px-3 py-2 rounded-md bg-blue-900 hover:bg-blue-700 transition-colors duration-300 text-white"
        >
          Refresh
        </button>
      </div>
      <Table rows={responses?.results} cols={tableCols} />
      <TableController paginationResponse={responses} />
    </>
  );
};

export default AllResponsesTable;
