import useQuery from "hooks/useQuery";
import PropTypes from "prop-types";

const TableController = ({ paginationResponse }) => {
  const { setQuery } = useQuery();

  const startNo =
    paginationResponse?.limit * paginationResponse?.page -
    paginationResponse?.limit +
    1;

  const docCount = Math.min(
    paginationResponse?.limit * paginationResponse?.page,
    paginationResponse?.limit * paginationResponse?.page -
      paginationResponse?.limit +
      paginationResponse?.results?.length
  );

  const totalDoc = paginationResponse?.totalDocs;

  return (
    <div className="flex flex-col items-center">
      {/* Help text */}
      <span className="text-sm text-gray-700 dark:text-gray-400">
        Showing
        <span className="font-semibold text-gray-900 dark:text-white px-1">
          {startNo}
        </span>{" "}
        to
        <span className="font-semibold text-gray-900 dark:text-white px-1">
          {docCount}
        </span>{" "}
        of
        <span className="font-semibold text-gray-900 dark:text-white px-1">
          {totalDoc}
        </span>
        Entries
      </span>
      {/* Buttons */}
      <div className="inline-flex mt-2 xs:mt-0">
        <button
          disabled={!paginationResponse?.hasPrevPage}
          onClick={() => setQuery("page", paginationResponse?.prevPage)}
          className="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-blue-700 rounded-s hover:bg-blue-800 dark:bg-blue-700 dark:border-blue-700 dark:text-blue-400 dark:hover:bg-blue-700 dark:hover:text-white disabled:bg-gray-500"
        >
          Prev
        </button>
        <button
          disabled={!paginationResponse?.hasNextPage}
          onClick={() => setQuery("page", paginationResponse?.nextPage)}
          className="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-blue-700 border-0 border-s border-blue-700 rounded-e hover:bg-blue-800 dark:bg-blue-700 dark:border-blue-700 dark:text-blue-400 dark:hover:bg-blue-700 dark:hover:text-white disabled:bg-gray-500"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TableController;

TableController.propTypes = {
  paginationResponse: PropTypes.shape({
    docs: PropTypes.object,
    hasNextPage: PropTypes.bool,
    hasPrevPage: PropTypes.bool,
    limit: PropTypes.number,
    nextPage: PropTypes.number,
    page: PropTypes.number,
    pagingCounter: PropTypes.number,
    prevPage: PropTypes.number,
    totalDocs: PropTypes.number,
    totalPage: PropTypes.number,
  }).isRequired,
};
