import { useState } from "react";
import { toast } from "react-toastify";
import { getWebsiteResponse, updateResponseFollowUp } from "utils/api";
import useQuery from "./useQuery";

const useResponses = () => {
  const [responses, setResponses] = useState();
  const [loading, setLoading] = useState(false);

  const { getQuery } = useQuery();

  // Will fetch the for response of the website
  const getResponse = async () => {
    setLoading(true);
    try {
      const { data } = await getWebsiteResponse({
        page: getQuery("page"),
        limit: getQuery("limit"),
      });
      setResponses(data.data);
    } catch (error) {}
    setLoading(false);
  };

  // To update the follow up status of the response
  const updateFollowUp = async (id, status) => {
    const loadingToast = toast.loading("Updating Follow up status...", {
      autoClose: false,
    });
    setLoading(true);
    try {
      await updateResponseFollowUp(id, { followUp: status });
      toast.update(loadingToast, {
        render: "Follow Up status updated successfully!",
        type: "success",
        autoClose: 3000,
        isLoading: false,
      });
    } catch (error) {
      toast.update(loadingToast, {
        render: "Follow Up status updation Failed!",
        type: "error",
        autoClose: 3000,
        isLoading: false,
      });
    }
    setLoading(false);
  };

  return { responses, getResponse, loading, updateFollowUp };
};

export default useResponses;
